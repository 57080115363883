<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 25.01.2021 21:15:10 UTC
          </div>
          <div id='tip'>
            There is one coin that's big, and that could go bigger than btc until btc's 41k level. That is DOT.
            You shouldn't leverage it, you should just spot it against the btc.
            My choice is still INJ for that thing, and smaller bag of hbar. DOT is if you have over 100 btc and buying small coins is a trouble for you. Cheers!
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DOT',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
